import useJwt from "@/auth/jwt/useJwt";
export default {
  data() {
    return {
      dataLuckyNumber: {
        Phone:''
      },
      isLoading: false,
      isDisabled: true,
    }
  },
  created()  {
    this.getLuckeyNumber();
  },
  watch: {

  },
  methods: {
    handleGetLuckyNumber() {
      this.getLuckeyNumber();
    },
    hadleByNow(item) {
      this.$emit('hadleByNow', item)
    },
    getLuckeyNumber() {
      this.isLoading = true;
      this.isDisabled = true;
      const number = [
        '0876 000303',
        '0878 121100',
        '0879  911144',
        '0877 231155',
        '0876 793344',
        '0875 536390',
        '0875 264268',
    ];
  const refreshIntervalId=   setInterval(() => {
        this.dataLuckyNumber.Phone =  number[Math.floor(Math.random() * number.length)];
      }, 50);
      useJwt.get("random-sim/10").then( result => {
        if (result && result.data && result.data.result) {
          setTimeout(() => {
            clearInterval(refreshIntervalId);
            this.isLoading = false;
            this.isDisabled = false;
            this.dataLuckyNumber = result.data.result;
            
          }, 1000);
        }
      }).catch(err => {
        this.isLoading = false;
      })
    },
    f087(value) {
      if(value) {
        return value.substr(0, 4) + " " + value.substr(4);
      }
     return '087 8998595'
    },
  }
}