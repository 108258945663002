<template>
  <div class="b2c-page-bg pb-4 pt-2">
<!--    <RegisterStep :currentStep="4" ></RegisterStep>-->
    <div class="register-form px-4 pb-2" >
      <div class="survey-order w-100 text-center">
        <img src="@/assets/images/finish-success.svg" class="mb-4" />
        <h3 v-if="isUpdateInfo">Cập nhật thông tin thuê bao thành công</h3>
        <h3 v-else>Đăng ký thông tin thuê bao thành công</h3>
        <h4 class="mb-2 text-12 mt-2 text-center" v-if="isUpdateInfo">
          iTel đã tiếp nhận hồ sơ cập nhật TTTB và gửi kết quả trong 24h. Trong
          thời gian này, TB của Bạn vẫn sử dụng bình thường.
        </h4>
        <h4 class="mb-2 text-12 mt-2 text-center" v-else>
          TB được kích hoạt thành công. iTel đã tiếp nhận hồ sơ đăng ký TTTB và
          gửi kết quả trong 24h. Hiện tại số TB của Bạn đã sẵn sàng sử dụng.
        </h4>
        <div class="survey-order-content">
          <div
            class="p-3 mb-4"
            style="background: rgba(242, 242, 242, 1); border-radius: 10px"
          >
            <div class="w-100 d-flex text-left">
              <div class="w-50">Số thuê bao:</div>
              <div class="w-50 font-weight-bolder">{{ phone }}</div>
            </div>
            <div class="w-100 d-flex text-left mt-2" v-if="!isUpdateInfo">
              <div class="w-50">Thời gian kích hoạt:</div>
              <div class="w-50 font-weight-bolder">{{timeRegister}}</div>
            </div>
          </div>
          <p class="pt-4 pb-2" style="border-top: 1px solid #ea0029" v-if="isUpdateInfo">
            Bạn cập nhật TTTB có dễ dàng không?<br />
            Hãy đánh giá ngay bên dưới nhé.<br />
            Cảm ơn Bạn!
          </p>
          <p class="pt-4 pb-2" style="border-top: 1px solid #ea0029" v-else>
            Bạn kích hoạt SIM có dễ dàng không?<br />
            Hãy đánh giá ngay bên dưới nhé.<br />
            Cảm ơn Bạn!
          </p>
          <div class="survey-order-content-rating">
            <b-form-rating
              style="border: none"
              id="rating-7"
              stars="7"
              v-model="params.score"
              variant="warning"
              size="lg"
            ></b-form-rating>
          </div>
          <div class="survey-order-content-txt">
            <p class="text">Rất khó khăn</p>
            <p class="text">Bình thường</p>
            <p class="text">Rất dễ dàng</p>
          </div>
        </div>
        <b-form-input
          v-model="params.content"
          type="text"
          placeholder="Góp ý khác"
          class="mb-2 text-14"
          style="height: 46px; padding-left: 26px"
        />
        <small class="text font-italic font-weight-norma"
          >Hãy góp ý cho chúng tôi để trải nghiệm của Bạn tốt hơn nhé!
        </small>
        <div class="row pt-1">
          <div class="col-6">
            <b-button class="style-button-back" @click="backToHome"
              >Hoàn thành
            </b-button>
          </div>
          <div class="col-6">
            <b-button
              class="style-button-contact"
              @click="$bvModal.show('modal-phone')"
            >
              <span class="font-weight-btn"> Liên hệ CSKH</span> <br />
              087 7087087
            </b-button>
          </div>
          <b-modal
            hide-header
            body-class="p-2"
            title-class="text-primary"
            hide-footer
            id="modal-phone"
            content-class="your-class"
          >
            <b-button class="mt-1 d-flex align-items-center style-btn" block>
              <a href=" tel:087 7087087">
                <span class="mr-2">
                  <img
                    loading="lazy"
                    src="@/assets/images/contact-phone.png"
                    alt="Hotline ITEL"
                  />
                </span>
                <span class="text-contact"> Gọi 087 7087087</span>
              </a>
            </b-button>
            <b-button
              class="mt-2 style-btn text-contact"
              block
              @click="$bvModal.hide('modal-phone')"
              >Hủy
            </b-button>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useJwt from "@/auth/jwt/useJwt";
import { BFormInput, BButton, BFormRating } from "bootstrap-vue";
import RegisterStep from "@/components/RegisterStep.vue";
import moment from 'moment';

export default {
  components: {
    BButton,
    BFormInput,
    BFormRating,
    RegisterStep,
  },
  data() {
    return {
      contribute: "",
      phone: "",
      params: {
        phone: "",
        sourceType: "",
        productType: "",
        orderId: "",
        score: null,
        content: "",
      },
      isUpdateInfo: false,
      timeRegister:moment().format('DD/MM/YYYY - hh:mm')
    };
  },
  mounted() {
    console.log("mounted", this.$route.params.slug);
    if (this.$route.params.slug === "cap-nhat") {
      this.isUpdateInfo = true;
    }
    this.phone = this.$route.params.phone;
    window.hideLayout(true)
  },
  methods: {
    backToHome() {
      this.onRating();
      // window.close();
      this.$router.push({ name: "home" });
    },
    getBrowser() {
      const ua = navigator.userAgent;
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          ua
        );
      return isMobile;
    },
    onRating() {
      this.params.phone = this.phone;
      this.params.orderId = this.phone;
      this.params.sourceType = this.getBrowser() ? "WebMobile" : "WebPC";
      this.params.productType = "Ekyc";
      useJwt
        .post("createSurveyCes", this.params)
        .then((result) => {
          if (result.status === 200) {
            this.$toast.success(
              "iTel cảm ơn bạn vì đã dành thời gian thực hiện khảo sát này!",
              { icon: true, closeButton: "button" }
            );
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}

.register-form {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
  font-size: medium;
  font-family: "roboto";
  font-weight: 400;
  background-color: white;
  border-radius: 15px;
}

.registerStep {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  font-size: medium;
  font-family: "roboto";
  font-weight: 400;
}

h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #5c5c5c;
}

.survey-order-content-txt {
  justify-content: space-between;
  margin: 0 7%;
  display: flex;
  padding-bottom: 30px;
}

.survey-order-content-rating {
  margin: 0 7%;
}

.style-button-contact {
  width: 100%;
  border-radius: 12px !important;
  font-size: 16px;
  font-weight: 500;
  color: #ed1f24 !important;
  background-color: white !important;
  border-color: #ed1f24 !important;
}

.font-weight-btn {
  font-weight: 300;
}

.style-button-back {
  width: 100%;
  border-radius: 12px !important;
  font-size: 16px;
  font-weight: 500;
  background-color: #ed1f24 !important;
  border-color: #ed1f24 !important;
}

.style-button {
  width: 100%;
  border-radius: 27px !important;
  font-size: 18px;
  font-weight: 600;
}

.text-14 {
  font-size: 14px;
}

.survey-order {
  font-size: 16px;
  padding-top: 28px;
  //   padding-bottom: 21px;
}

.style-btn {
  background: #fff !important;
}

.text-contact {
  color: #007bff !important;
  font-size: 18px;
}

@media screen and (max-width: 460px) {
  .survey-order-content-rating,
  .survey-order-content-txt {
    margin: 0;
  }
}

@media screen and (max-width: 400px) {
  .style-button-contact {
    font-size: 14px;
  }
  .style-button-back {
    font-size: 14px;
  }
}
</style>